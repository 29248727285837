<template>
	<Metier name="vern" title="Dépôt de Vern-Sur-Seiche" :main-menu="[{title: 'Contremaître adjoint Exploitation Vern', missions: ['Assure le bon fonctionnement de l\'exploitation du dépôt', 'Prend en charge une partie des tâches d\'exploitation', 'S\'assure de la bonne application des règles de sécurité', 'Participe au suivi budgétaire en collaboration avec le chef de dépôt'], competences: ['Connaissance du milieu pétrolier et industriel', 'Connaissance des règles et procédures de sécurité', 'Connaissance de la réglementation douanière'], qualites: ['Leadership', 'Rigueur', 'Organisation']}, {title: 'Opérateur polyvalent Vern', missions: ['Assure le bon fonctionnement du matériel et des installations du dépôt', 'Contrôle la réception et l\'expédition des produits', 'Jauge les stocks de produits et prélève les échantillons pour analyse', 'Participe à la sécurité du site'], competences: ['Connaissance des installations du site', 'Connaissance des produits qu\'il est amené à manipuler', 'Connaissance des règles et procédures de sécurité'], qualites: ['Rigueur', 'Esprit d\'équipe', 'Disponibilité']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
